import booths from "../locations.json";

export const locations = {
  isValidLocationAndLock(locationId, lockId) {
    return booths.some((booth) => booth.locationId === locationId && booth.lockId === lockId);
  },
  getLocationCostPerMinute(locationId) {
    return booths.find((booth) => booth.locationId === locationId).costPerMinute;
  },
};
