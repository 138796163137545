import React from "react";
import { Container, Box, Paper, Typography, TextField, Button, InputAdornment, Alert } from "@mui/material";
import { formatIncompletePhoneNumber, isValidPhoneNumber, parsePhoneNumberWithError as parsePhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../services/api";
import QrCodeScanPage from "./QrCodeScanPage";
import { locations } from "../utils/locaitons.util";

export default function Registration() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const locationId = params.get("locationId");
  const lockId = params.get("lockId");

  if (!locationId || !lockId || !locations.isValidLocationAndLock(locationId, lockId)) return <QrCodeScanPage />;

  const validatePhoneNumber = (phoneNumber) => {
    try {
      return isValidPhoneNumber(phoneNumber, "US");
    } catch (error) {
      return false;
    }
  };

  const handlePhoneChange = (element) => {
    let value = element.target.value;
    const numericValue = value.replace(/\D/g, "");

    if ((numericValue.startsWith("1") && numericValue.length > 11) || (!numericValue.startsWith("1") && numericValue.length > 10)) {
      return;
    }

    const formattedValue = formatIncompletePhoneNumber(numericValue, "US");

    if (value.length < phoneNumber.length) setPhoneNumber(value);
    else setPhoneNumber(formattedValue);
  };

  const formatPhoneforApi = (phoneNumber) => {
    try {
      if (!phoneNumber.trim()) {
        return null;
      }

      const parsedPhoneNum = parsePhoneNumber(phoneNumber, "US");
      return parsedPhoneNum.format("E.164");
    } catch (error) {
      return null;
    }
  };

  const handleSubmit = async (el) => {
    el.preventDefault();
    setError(null);

    if (!validatePhoneNumber(phoneNumber)) {
      setError("Please enter a valid phone number");
      return;
    }

    setIsLoading(true);

    try {
      const formattedPhoneNumber = formatPhoneforApi(phoneNumber);

      if (!formattedPhoneNumber) throw new Error("Invalid phone number format");

      const response = await registerUser({ phoneNumber: formattedPhoneNumber, locationId, lockId });

      if (!response?.setupIntent) {
        throw new Error("Invalid response from server: missing setup intent");
      }

      if (!response?.userId) {
        throw new Error("Invalid response from server: missing user ID");
      }

      navigate("/payment-capture", {
        state: {
          setupIntent: response.setupIntent,
          userId: response.userId,
          locationId,
          lockId,
        },
      });
    } catch (error) {
      setError(error.message || "Unable to complete registration. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">Loading...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Paper sx={{ p: 4, width: "100%" }}>
          {/* <ProgressStepper currentStep={1} /> */}
          <Typography variant="h4" sx={{ textAlign: "center", mb: 6, mt: 4 }}>
            Registration
          </Typography>
          <Typography variant="body1" color="secondary" align="center" gutterBottom>
            Enter your phone number to get started
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              required
              label="Phone Number"
              margin="normal"
              placeholder="(123) 456-7890"
              onChange={handlePhoneChange}
              value={phoneNumber}
              sx={{ mt: 2 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+1</InputAdornment>,
              }}
            />
            <Button type="submit" variant="contained" fullWidth size="large" sx={{ mt: 3 }} disabled={isLoading}>
              Submit
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}
