import { Container, Box, Paper, Typography, Alert, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { QrCode2 } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import locations from "../locations.json";
import { Link } from "react-router-dom";
export default function QrCodeScanPage() {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            width: "100%",
            textAlign: "center",
          }}
        >
          <QrCode2 sx={{ fontSize: 80, color: "primary.main", mb: 2 }} />
          <Typography variant="h4" component="h1" gutterBottom>
            Scan QR Code
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Please scan the QR code on your QuietBox to get started or pick a location below
          </Typography>

          <List sx={{ mt: 3 }}>
            {locations.map((location, index) => (
              <ListItem key={index} component={Link} to={`/register?locationId=${location.locationId}&lockId=${location.lockId}`} button>
                <ListItemIcon>
                  <LocationOnIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={location.name} />
                <ListItemText secondary={location.address} />
              </ListItem>
            ))}
          </List>

          {process.env.REACT_APP_NODE_ENV === "development" && (
            <Alert severity="info" sx={{ mt: 3 }}>
              Developer Note: Add locationId and lockId parameters to URL:
              <Box component="code" sx={{ display: "block", mt: 1, p: 1, bgcolor: "grey.100" }}>
                http://localhost:3000?locationId=loc123&lockId=lock456
              </Box>
            </Alert>
          )}
        </Paper>
      </Box>
    </Container>
  );
}
